import httpClient from './httpClient';

const END_POINT = '/quotes';


const getAllQuotes = () => httpClient.get(END_POINT);

// you can pass arguments to use as request parameters/data
const getQuote = (id) => httpClient.get(END_POINT + '/' + id);
// maybe more than one..
const createQuote = (quote) => httpClient.post(END_POINT, { quote });

const updateQuote = (id, quote) => httpClient.put(END_POINT + '/' + id, { quote } );

const deleteQuote = (id) => httpClient.delete(END_POINT + '/' + id);

const convertToInvoice = (id, project_id) => httpClient.post(END_POINT + '/' + id + '/convert-to-invoice' + '/' + project_id)

const generateQuote = (id) => httpClient.get(END_POINT + '/' + id + '/print');

const duplicateQuote = (id) => httpClient.post(END_POINT + '/' + id + '/duplicate')

export {
    getAllQuotes,
    getQuote,
    createQuote,
    updateQuote,
    deleteQuote,
    convertToInvoice,
    generateQuote,
    duplicateQuote
}

